/*
  Icon Font: service-map-icons
*/

@font-face {
  font-family: "service-map-icons";
  src: url("./service-map-icons_8b181b1a4d8d28c9a14e21b7e794967e.eot");
  src: url("./service-map-icons_8b181b1a4d8d28c9a14e21b7e794967e.eot?#iefix")
      format("embedded-opentype"),
    url("./service-map-icons_8b181b1a4d8d28c9a14e21b7e794967e.woff")
      format("woff"),
    url("./service-map-icons_8b181b1a4d8d28c9a14e21b7e794967e.ttf")
      format("truetype"),
    url("./service-map-icons_8b181b1a4d8d28c9a14e21b7e794967e.svg#service-map-icons")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "service-map-icons";
    src: url("./service-map-icons_8b181b1a4d8d28c9a14e21b7e794967e.svg#service-map-icons")
      format("svg");
  }
}

[data-icon]:before {
  content: attr(data-icon);
}

[data-icon]:before,
.icon-icon-accessibility-notice:before,
.icon-icon-address:before,
.icon-icon-areas-and-districts:before,
.icon-icon-back:before,
.icon-icon-back-bold:before,
.icon-icon-bicycle:before,
.icon-icon-browse:before,
.icon-icon-bus:before,
.icon-icon-by-foot:before,
.icon-icon-car:before,
.icon-icon-checked:before,
.icon-icon-close:before,
.icon-icon-coat-of-arms-espoo:before,
.icon-icon-coat-of-arms-helsinki:before,
.icon-icon-coat-of-arms-kauniainen:before,
.icon-icon-coat-of-arms-vantaa:before,
.icon-icon-collapse:before,
.icon-icon-colour-blind:before,
.icon-icon-copyright:before,
.icon-icon-embed:before,
.icon-icon-events:before,
.icon-icon-expand:before,
.icon-icon-feedback:before,
.icon-icon-ferry:before,
.icon-icon-forward:before,
.icon-icon-forward-bold:before,
.icon-icon-hearing-aid:before,
.icon-icon-info:before,
.icon-icon-link:before,
.icon-icon-list:before,
.icon-icon-loading:before,
.icon-icon-map-options:before,
.icon-icon-measuring-tool:before,
.icon-icon-more:before,
.icon-icon-opening-hours:before,
.icon-icon-outbound-link:before,
.icon-icon-personalise:before,
.icon-icon-photo:before,
.icon-icon-pick-up-traffic:before,
.icon-icon-public-transport:before,
.icon-icon-reduced-mobility:before,
.icon-icon-reply:before,
.icon-icon-rollator:before,
.icon-icon-search:before,
.icon-icon-service-map-logo:before,
.icon-icon-service-map-logo-fi:before,
.icon-icon-service-map-logo-se:before,
.icon-icon-settings:before,
.icon-icon-share:before,
.icon-icon-show-service-points:before,
.icon-icon-show-service-points-single:before,
.icon-icon-statistics:before,
.icon-icon-stroller:before,
.icon-icon-subway:before,
.icon-icon-tools:before,
.icon-icon-train:before,
.icon-icon-tram:before,
.icon-icon-urgent:before,
.icon-icon-vice-versa:before,
.icon-icon-visually-impaired:before,
.icon-icon-web-services:before,
.icon-icon-wheelchair:before,
.icon-icon-you-are-here:before,
.icon-icon-zoom-in:before,
.icon-icon-zoom-out:before {
  display: inline-block;
  font-family: "service-map-icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-icon-accessibility-notice:before {
  content: "\f120";
}
.icon-icon-address:before {
  content: "\f132";
}
.icon-icon-areas-and-districts:before {
  content: "\f138";
}
.icon-icon-back:before {
  content: "\f100";
}
.icon-icon-back-bold:before {
  content: "\f101";
}
.icon-icon-bicycle:before {
  content: "\f102";
}
.icon-icon-browse:before {
  content: "\f103";
}
.icon-icon-bus:before {
  content: "\f121";
}
.icon-icon-by-foot:before {
  content: "\f104";
}
.icon-icon-car:before {
  content: "\f105";
}
.icon-icon-checked:before {
  content: "\f133";
}
.icon-icon-close:before {
  content: "\f106";
}
.icon-icon-coat-of-arms-espoo:before {
  content: "\f128";
}
.icon-icon-coat-of-arms-helsinki:before {
  content: "\f129";
}
.icon-icon-coat-of-arms-kauniainen:before {
  content: "\f12a";
}
.icon-icon-coat-of-arms-vantaa:before {
  content: "\f12b";
}
.icon-icon-collapse:before {
  content: "\f107";
}
.icon-icon-colour-blind:before {
  content: "\f126";
}
.icon-icon-copyright:before {
  content: "\f137";
}
.icon-icon-embed:before {
  content: "\f139";
}
.icon-icon-events:before {
  content: "\f108";
}
.icon-icon-expand:before {
  content: "\f109";
}
.icon-icon-feedback:before {
  content: "\f119";
}
.icon-icon-ferry:before {
  content: "\f130";
}
.icon-icon-forward:before {
  content: "\f10a";
}
.icon-icon-forward-bold:before {
  content: "\f10b";
}
.icon-icon-hearing-aid:before {
  content: "\f10c";
}
.icon-icon-info:before {
  content: "\f135";
}
.icon-icon-link:before {
  content: "\f13a";
}
.icon-icon-list:before {
  content: "\f140";
}
.icon-icon-loading:before {
  content: "\f13b";
}
.icon-icon-map-options:before {
  content: "\f118";
}
.icon-icon-measuring-tool:before {
  content: "\f13c";
}
.icon-icon-more:before {
  content: "\f10d";
}
.icon-icon-opening-hours:before {
  content: "\f11a";
}
.icon-icon-outbound-link:before {
  content: "\f11d";
}
.icon-icon-personalise:before {
  content: "\f122";
}
.icon-icon-photo:before {
  content: "\f131";
}
.icon-icon-pick-up-traffic:before {
  content: "\f127";
}
.icon-icon-public-transport:before {
  content: "\f10e";
}
.icon-icon-reduced-mobility:before {
  content: "\f10f";
}
.icon-icon-reply:before {
  content: "\f136";
}
.icon-icon-rollator:before {
  content: "\f110";
}
.icon-icon-search:before {
  content: "\f111";
}
.icon-icon-service-map-logo:before {
  content: "\f12c";
}
.icon-icon-service-map-logo-fi:before {
  content: "\f12d";
}
.icon-icon-service-map-logo-se:before {
  content: "\f12e";
}
.icon-icon-settings:before {
  content: "\f134";
}
.icon-icon-share:before {
  content: "\f13d";
}
.icon-icon-show-service-points:before {
  content: "\f11e";
}
.icon-icon-show-service-points-single:before {
  content: "\f11f";
}
.icon-icon-statistics:before {
  content: "\f13e";
}
.icon-icon-stroller:before {
  content: "\f112";
}
.icon-icon-subway:before {
  content: "\f123";
}
.icon-icon-tools:before {
  content: "\f13f";
}
.icon-icon-train:before {
  content: "\f124";
}
.icon-icon-tram:before {
  content: "\f125";
}
.icon-icon-urgent:before {
  content: "\f11b";
}
.icon-icon-vice-versa:before {
  content: "\f12f";
}
.icon-icon-visually-impaired:before {
  content: "\f113";
}
.icon-icon-web-services:before {
  content: "\f11c";
}
.icon-icon-wheelchair:before {
  content: "\f114";
}
.icon-icon-you-are-here:before {
  content: "\f115";
}
.icon-icon-zoom-in:before {
  content: "\f116";
}
.icon-icon-zoom-out:before {
  content: "\f117";
}
