.unit-geometry {
  &__track {
    stroke: $status-unknown;
    stroke-width: 4px;

    &--good {
      stroke: $status-ok;
    }

    &--satisfactory {
      stroke: $status-average;
    }

    &--unusable {
      stroke: $status-bad;
    }
  }

  &__hilight {
    stroke-width: 8px;
    opacity: 0.8;
    stroke-dasharray: 1cap;
    stroke: $status-selected;
  }

  &__click-area {
    stroke-width: 12px;
    opacity: 0;
  }
}
