.jump-link.jump-link {
  position: fixed;
  top: 2px;
  left: 2px;
  z-index: 10000;
  padding: 2rem 4rem;

  color: #fff;
  text-decoration: none;
  font-weight: bold;

  background: #000;

  &:not(:focus) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
