.unit-popup {
  .leaflet-popup-content-wrapper {
    border-radius: 0;
    padding: 0 0 calc($gap/2) 0;

    .leaflet-popup-content {
      padding: 0;
      margin: 0;
    }
  }

  &__content {
    div {
      padding: calc($gap/2) $gap;
    }

    &__status {
      background: $status-unknown;
      color: $ui-text;
      text-transform: uppercase;
      font-size: 10px;

      &--good {
        background: $status-ok;
      }

      &--satisfactory {
        background: $status-average;
      }

      &--unusable {
        background: $status-bad;
      }
    }

    &__name {
      padding: $gap;
      margin: 0;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-size: 12px;
    }
  }
}
