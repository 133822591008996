.map-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.map-foreground {
  position: fixed;
  z-index: 1010;
  background: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.is-filled {
    background-color: $ui-content;

    @media only screen and (min-width: 768px) {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    &.fill-color-background {
      // When foreground is filled with background color, it should always fill
      // the entire screen height, but not more, to enable proper behaviour with
      // scrolling.
      height: 100vh;

      border-bottom: solid #4d4d4d 4px;
      background-color: $ui-background;
      box-shadow: none;
    }
  }

  @media only screen and (min-width: $breakpoint) {
    width: 400px;
    height: 100vh;

    pointer-events: none;

    & > * {
      pointer-events: initial;
    }
  }

  &-content {
    overflow-y: auto;
    pointer-events: none;
    display: flex;
    flex-direction: column;

    & > * {
      pointer-events: initial;
    }

    &.hidden {
      display: none;
    }
  }

  &-unit-browser {
    height: 100%;
    pointer-events: none;

    & > * {
      pointer-events: initial;
    }

    &.hidden {
      display: none;
    }
  }
}

.home-container {
  @media only screen and (min-width: 768px) {
    display: none;
  }

  padding: 10px;
  color: var(--color-info-light);
  text-align: right;

  &-icon {
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;

    &-expanded {
      transform: rotate(-180deg);
      transition: transform 0.5s ease-in-out
    }
  }
}
