.app-info-dropdown {
  position: fixed;
  z-index: 1002;
  left: 10px;
  bottom: 10px;

  @media only screen and (min-width: $breakpoint) {
    left: unset;
    bottom: unset;
    top: 10px;
    right: 10px;
  }

  .dropdown-toggle {
    height: 26px;
    width: 26px;
    padding: 0;

    line-height: 26px;

    border: none;
    border-radius: 50%;
    box-shadow: 0 1px 5px rgba(71, 55, 55, 0.6);

    &:not(:focus) {
      color: inherit;
      background: white;
    }

    & > * {
      font-size: 19px;
      line-height: 26px;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    border-radius: 0;
  }
  .dropdown-item {
    padding-left: 0.75rem;
  }

  &__icon {
    padding-right: 0.5rem;
    display: inline-block;
    width: 24px;
  }
}
