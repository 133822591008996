.search-bar {
  &__input {
    padding: $gap $gap;
    background: $ui-content;
    display: flex;
    align-items: center;
    position: relative;

    input[name="search"] {
      flex: 2 1 auto;
      display: inline-block;
      border: 2px solid $ui-content;
      border-radius: 30px;
      padding: 0 $gap;
      line-height: 30px;
      height: 34px;
      font-family: "Lato", sans-serif !important;
      width: 100%;

      &:focus {
        box-shadow: 0 0 0 2px $content-background !important;
        outline: none;
      }

      &:disabled:hover {
        cursor: not-allowed;
      }
    }

    &-clear {
      position: absolute;
      right: 40px;

      border: none;
      background: none;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &-loading {
      position: absolute;
      left: 16px;
      animation: spin 1s linear infinite;
    }

    &-submit {
      flex: 0 1 auto;
      display: inline-block;
      padding-right: 6px;
      margin: 0;
      width: 24px;

      font-size: 18px;
      line-height: 30px;
      color: $ui-text;

      border: none;
      background: none;
    }
  }
}
