.feedback-modal {
  &__feedback {
    width: 80%;
  }

  &__email {
    width: 80%;
  }

  &__checkbox {
    margin-right: 8px !important;
  }
}
