.unit-sort-selector {
  width: 100%;

  .dropdown-toggle {
    width: 100%;
    background: $ui-content !important;
    border: 0;
    padding: $gap 2 * $gap;
    color: $ui-text !important;
    text-align: left;
    border-radius: 0;
    box-shadow: none !important;
    font-family: "Lato", sans-serif !important;

    .custom-caret {
      display: inline-block;
      position: absolute;
      right: 2 * $gap;
      font-size: 24px;
      line-height: 20px;
    }

    &:active {
      background: darken($ui-content, 10%);
    }

    &:hover {
      background: $ui-content;
      color: $ui-text;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    position: relative;
    width: 100%;
    margin: 0;
    border: 0;
    border-radius: 0;
    padding: 0;
    box-shadow: none !important;
    font-family: "Lato", sans-serif !important;

    li {
      background: $ui-disabled !important;

      a {
        color: $ui-text !important;
        padding: $gap 2 * $gap;

        &:hover {
          background: $sort-selector-dropdown-hover !important;
        }

        &:active {
          background: darken($ui-disabled, 10%) !important;
        }
      }
    }
  }

  //  &.open {
  //    .dropdown-toggle, .dropdown-toggle:active {
  //      background: $ui-content !important;
  //      color: $ui-text !important;
  //    }
  //  }
}
