.unit-browser {
  pointer-events: none;
  overflow: hidden;
  height: unset;
  display: flex;
  flex-direction: column;

  & > * {
    pointer-events: auto;
    padding: calc($gap / 2) $gap;

    @media only screen and (min-width: $breakpoint) {
      padding: $gap;
    }
  }

  .unit-browser__content {
    padding-top: 0;
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .unit-browser__tmp_msg {
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 3px 10px;
    font-weight: 700;
    background-color: rgba(255, 255, 255, 0.85);
    overflow: hidden;
    margin-left: 8px;
    margin-right: 8px;
    width: auto;
    text-align: center;
  }
  @media only screen and (min-width: $breakpoint) {
    .unit-browser__tmp_msg {
      width: 384px;
    }

    .unit-browser__tmp_msg a {
      font-size: 20px;
      color: #dd0000;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .unit-browser__tmp_msg a {
      font-size: 16px;
      color: #dd0000;
    }
  }

  .header {
    width: 100%;
    display: flex;
    margin-bottom: calc($gap / 2);

    @media only screen and (min-width: $breakpoint) {
      margin-bottom: $gap;
    }

    .search-container {
      flex: 1;
      height: 100%;
    }

    .action-buttons {
      margin-left: 4px;
      flex-shrink: 0;
    }

    .action-button {
      margin-left: 2px;
      height: 50px;
      width: 50px;
      background: $ui-content;
      color: $ui-text;
      border: 0;
      line-height: 24px;
      &[aria-pressed="true"] {
        background: $ui-enabled;
      }

      & > .unit-browser__action {
        font-size: 24px;
      }
    }
  }
}

.address-bar {
  &__container {
    border-radius: 18px;
    padding: 3px 10px;
    background: $ui-content;
    color: #fff;
    margin-top: $gap;
    display: block;
    border: none;
    width: 100%;
    text-align: left;

    &:hover {
      background: $ui-enabled;
      cursor: pointer;
    }
  }

  &__marker {
    margin-right: 4px !important;
  }
}

@media only screen and (min-width: $breakpoint) {
  .unit-browser {
    .header .search-container .search-suggestions {
      width: 400px;
    }

    &.expanded {
      height: 100%;
      max-height: 100%;
    }
  }
}
