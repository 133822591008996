/*
  Icon Font: outdoor-sports-icons
*/

@font-face {
  font-family: "outdoor-sports-icons";
  src: url("./outdoor-sports-icons.eot");
  src: url("./outdoor-sports-icons.eot?#iefix") format("embedded-opentype"),
    url("./outdoor-sports-icons.woff") format("woff"),
    url("./outdoor-sports-icons.ttf") format("truetype"),
    url("./outdoor-sports-icons.svg#outdoor-sports-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "outdoor-sports-icons";
    src: url("./outdoor-sports-icons.svg#outdoor-sports-icons") format("svg");
  }
}

[data-icon]:before {
  content: attr(data-icon);
}

[data-icon]:before,
.icon-crosscountry:before,
.icon-iceskate:before,
.icon-locate:before,
.icon-location:before,
.icon-status-ok:before,
.icon-status-unknown:before,
.icon-swim:before {
  display: inline-block;
  font-family: "outdoor-sports-icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-crosscountry:before {
  content: "\f100";
}
.icon-iceskate:before {
  content: "\f101";
}
.icon-locate:before {
  content: "\f102";
  font-size: 19px;
  line-height: 26px;
}
.icon-location:before {
  content: "\f103";
}
.icon-status-ok:before {
  content: "\f104";
}
.icon-status-unknown:before {
  content: "\f105";
}
.icon-swim:before {
  content: "\f106";
}
