.unit-filters {
  max-width: 100%;
  width: 100%;
  position: relative;
  background: $ui-content;
  color: $ui-text;

  & + .unit-filters {
    border-top: 1px solid $ui-enabled;
  }

  &__filters {
    position: unset;
    width: unset;
  }

  &__filters.container,
  &__options.container {
    max-width: 100%;
  }

  &__filters.row,
  &__options.row {
    padding: $gap;
  }

  &__filters.toggle-filters {
    display: flex;
    width: 100%;
    flex-wrap: wrap
  }

  &__options-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: unset;
    margin-top: $gap;

    background: $ui-disabled;
  }

  &__options {
    background: $ui-disabled;
    color: $ui-text;
  }

  &__options-separator {
    margin: 0 -5px;
    border-top: 1px solid $ui-enabled;
  }

  &__edit {
    position: unset;
  }

  &__edit,
  &__option {
    padding: 0;

    &:nth-child(n + 3) {
      margin-top: $gap;
    }

    &:nth-child(even) {
      padding-left: calc($gap/2);
    }

    &:nth-child(odd) {
      padding-right: calc($gap/2);
    }
  }

  .unit-filter-button {
    width: 100%;
    background: transparent;
    border: 1px solid $ui-text-disabled;
    color: inherit;
    padding: 2px $gap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 999999px;
    font-size: 14px;
    line-height: 1;
    min-height: 30px;

    @media only screen and (min-width: $breakpoint) {
      min-height: 34px;
    }

    &__icon {
      padding-right: 3px;
      font-size: $icon-size;
      line-height: 1;
    }

    &.active,
    &:active {
      background-color: $ui-enabled;
      color: $ui-text;
      box-shadow: none;
    }

    &:hover {
      border-color: $ui-text;
    }

    .dropdown-indicator {
      margin-left: auto;
    }
  }

  .unit-filter-toggle-button {
    padding: $gap;
    color: #fff;
    flex: 1 0 auto;

    label {
      color: #fff;
      font-size: 1em;
      margin-bottom: 3px;
      font-weight: 400;
      line-height: 1;
    }

    button {
      --toggle-button-unselected-color: #{$ui-text-disabled} !important;
      height: 30px;
      border-width: 1px;
    }

    button:not([aria-pressed=true]) {
      background: transparent;
    }
  }
}
