$header-height: 66px;
$gap: 8px;
$breakpoint: 768px;
$marker-height: 40px;
$icon-size: 20px;

// COLORS
$ui-background: #77d5ed;
$ui-content: #0072c6;
$ui-enabled: #2d5c90;
$ui-disabled: #61abd3;
$ui-text: #ffffff;
$ui-text-disabled: #c1dded;
$status-ok: #72bc3d;
$status-average: #f5c01a;
$status-bad: #222;
$status-unknown: #a8b5c2;
$status-selected: #0000FF;
$content-background: #ffffff;
$content-text: #333333;
$content-text-secondary: #4d4d4d;
$unit-filter-hover: #036ea4;
$sort-selector-dropdown-hover: #30a1d5;
$list-view-item-hover: #e5f4fa;

// Lato font
@import url("https://fonts.googleapis.com/css?family=Lato:light,400,700");
