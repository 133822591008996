.unit-container {
  max-height: 30vh;
  padding: 10px;
  overflow-y: auto;
  transition: max-height 0.5s ease-in-out;

  &.expanded {
    max-height: 80vh;
    transition: max-height 0.5s ease-in-out;
  }

  @media only screen and (min-width: 768px) {
    max-height: unset;
    height: 100%;
  }

  h4 {
    margin: 10px 0;

    font-size: 18px;
    line-height: 1.1;
  }

  p {
    margin-bottom: 10px;

    &.no-margin {
      margin-bottom: 0;
    }
  }

  .modal-body-list li {
    margin-bottom: 10px;
  }

  &-header {
    padding: 10px;
    color: #fff;
    border: none;

    &-container {
      width: 100%;
    }

    &-name {
      display: flex;
      justify-content: space-between;

      .close-unit-container {
        color: $ui-text;
        font-size: 1.4em;
      }
    }

    &-description {
      padding-top: 5px;
      border-top: 1px solid #fff;
      font-size: 12px;
      display: flex;

      p {
        margin-bottom: 5px;
      }

      .unit-icon {
        margin-right: 10px;
        font-size: 3em;
        line-height: 1;
      }
    }
  }

  &-body {
    padding: $gap 0;

    &-box {
      background: #fff;
      padding: 10px;
      align-items: center;

      &:empty {
        display: none;
      }

      & + & {
        margin-top: $gap;
      }

      &-headline {
        font-weight: 700;
        margin-bottom: 3px;
      }

      &-icon-and-value-wrapper {
        margin: calc($gap / 2) 0 0 0;
      }

      &-icon-and-value {
        display: inline-flex;
        align-items: center;
        margin: 0 calc($gap * 2) $gap 0;

        svg {
          background-color: rgba($ui-content, .1);
          color: $ui-content;
          padding: 2px;
          font-size: 2em;
          flex-grow: 0;
          margin-right: calc($gap / 1.5);
        }
      }
    }

    &-multi-line {
      white-space: pre-wrap;
      margin-top: 2rem;

      & > * {
        display: inline-block;
      }
    }

    &-list {
      padding: 0;
      margin: 0;
      list-style-type: none;

      & > li {
        margin-bottom: 1rem;
      }
    }
  }

  &-close-button {
    &:hover {
      cursor: pointer;
    }
  }
}

.unit-details-mobile-footer {
  @media only screen and (min-width: 768px) {
    display: none;
  }

  padding: 10px;
  color: var(--color-info-light);
  text-align: right;

  &-icon {
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;

    &-expanded {
      transform: rotate(-180deg);
      transition: transform 0.5s ease-in-out
    }
  }
}
.water-quality{
  &-normal {
    background-color: #008450;
  }
  &-possibly_impaired {
    background-color: #EFB700;
  }
  &-probably_impaired {
    background-color: #EFB700;
  }
  &-error {
    background-color: #a8b5c2;
  }
}

.favorite-button {
  background: none;
  color: #0d6efd;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  display: block;
  width: 100%;
  text-align: left;
}

.favorite-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
