.observation-status {
  &__bar {
    padding: 0 2px;
    width: 100%;
    text-transform: uppercase;
    font-size: 0.8em;
    color: $ui-text;

    &--good {
      background: $status-ok;
    }

    &--satisfactory {
      background: $status-average;
    }

    &--unusable {
      background: $status-bad;
      color: white;
    }

    &--unknown {
      background: $status-unknown;
    }
  }

  &__time {
    color: $content-text-secondary;
  }
}
