body {
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  overflow: hidden;
}

html,
body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

#app-wrapper {
  height: 100%;
}
